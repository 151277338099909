<template>  
  <button @click="active = !active; $emit('toggled', active)" :class="[active ? 'active' : '']">      
      <span>Additional Information</span>
      <svg width="7" height="9" viewBox="0 0 7 9" xmlns="http://www.w3.org/2000/svg">
        <path d="m0 9 7-4.5L0 0z" fill="#F5F5F5" fill-rule="nonzero"/>
      </svg>
  </button>
</template>

<script>
export default {
    name:'ToggleButton',
    props: {        
    },
    methods : {       
    },
    data() {
        return {
            active:false,
        }
    },
}
</script>

<style lang="scss" scoped> 
    button {
        background:none;
        border:none;
        color: #F5F5F5;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1;
        margin-top:12px;
        margin-bottom:12px;
        font-family: 'Gotham Book';
        cursor: pointer;
    }    
    svg {
        margin-left:16px;
        transition: transform 0.2s ease-in-out;
        transform: rotateZ(270deg);
    }
    .active svg {
        transform: rotateZ(90deg);
    }
    @media (hover: hover) {
      button {        
        &:hover {
              opacity: 1;            
        }
      }      
    }   
</style>
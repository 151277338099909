<template>
  <div class='template'>    
      <article class="images">
          <transition name="fade" appear>
            <div :style="{backgroundImage:`url(${page?.background})`}" />
          </transition>
      </article>  
      <article class='text-content' :class="[showInformation ? 'show' :'']">
          <transition name="slide" appear>
            <div>
                <div>
                    <h1>{{page.title}}</h1>
                    <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                </div>
                <div class='ui-holder'>       
                    <ToggleButton @toggled="setToggled" v-if="copy !== '' || gallery.length"/>
                    <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="videoVisible = true" flipped/>
                </div>                     
            </div>
          </transition>          
      </article>      
      <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
      </div> 
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ToggleButton from '@/components/ToggleButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Full Width Collapsible Copy',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle,
        ToggleButton
    },
    methods : {
        setToggled(value){
            this.showInformation = value;
        }
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            showInformation:false,    
            copy:this.page.copy  
        }
    },
    computed: {                
        gallery() {
            let images = [];        
            for (let index = 0; index < this.page?.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .ui-holder {
        text-align: right;        
        .ui {
            justify-content: flex-end;
        }
        > button {
            white-space: nowrap;
        }
        @media (max-width:699px) {
            > button {
                display:none;
            }
        }
    }
    .text-content > div {
        position: relative;
        max-width: 1140px;
        margin:0 auto;
    }
    .copy {        
        overflow: auto;
        padding-right: 25px;
    }
    .text-content {
        background: linear-gradient(134.76deg, #202020 0%, #121212 100%);                
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #F5F5F5;        
        width:100%;                 
        padding:60px;
        &.show {
            transform:translateY(0);     
        }
        @media (min-width:700px) {
            padding:20px 130px;
            padding-bottom: 0;
            height:50vh;
            position: absolute;
            bottom:0;
            left:0;
            transition: transform 0.2s ease-in-out;
            transform:translateY(calc(100% - 75px));     
            h1 {
                white-space: nowrap;
            }
            .copy {
                max-height: calc(50vh - 108px);
            }
            > div:not(.linear-holder) {
                display:grid;
                grid-template-columns: 1fr 1fr;    
                gap:30px;
                height:100%;            
            }
        }
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }
    h1 {
        margin:0 16px;
        text-transform: uppercase;
        margin-bottom:32px;
        margin-left: 0;
        max-width: 550px;   
        
        color: #F5F5F5;
        font-size: 2.25rem;
        letter-spacing: 0;
        line-height: 1;
        position: relative;
        &:before {
            content:"";
            position: absolute;
            width:5px;
            height:100%;
            background-color: #0066CC;
            top:0;
            left:-15px;
        }
    }    
    .images {        
        display:grid;
        gap:1px;       
        height:50vh;
        @media (min-width:700px) {        
            height:100%;              
        }  
        > div {
            background-size: cover;
            background-position: center;
            -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
            color-adjust: exact;     
            overflow: hidden;
            display: flex;
            flex-direction: column;    
            position: relative;        
        }            
    }
</style>